window.ManageAccount = {};

$(document).ready(function () {
  var birthDate = [$('.js-year').val(), $('.js-month').val(), $('.js-day').val()].join('-');
  $('.js-birth-date').val(birthDate);

  var $formUpdateUser = $('form[name="formUpdateUser"]');
  var $formUpdatePwd = $('form[name="formUpdatePwd"]');

  ManageAccount.updateUIWhenSavedUser = function () {
    $formUpdateUser.find('.js-box-background').addClass('account-box');
    $formUpdateUser.find('.js-edit-button').removeClass(hidden);
    $formUpdateUser.find('.js-firstname').attr(disabled, disabled);
    $formUpdateUser.find('.js-lastname').attr(disabled, disabled);
    $formUpdateUser.find('.js-date-picker').attr(disabled, disabled);
    $formUpdateUser.find('.js-check-date-error').addClass('form-disabled');
    $formUpdateUser.find('.js-group-button').addClass(hidden);

    $('.js-delete-account').addClass(hidden);
    $('.js-notification').removeClass(hidden);
  };

  ManageAccount.updateUIWhenSavedUserPwd = function () {
    $formUpdatePwd.find('.js-box-background').addClass('account-box');
    $formUpdatePwd.find('.js-edit-button').removeClass(hidden);
    $formUpdatePwd.find('.js-current-pwd').removeClass(hidden);
    $formUpdatePwd.find('.js-change-password').addClass(hidden);

    $('.js-delete-account').addClass(hidden);
    $('.js-notification').removeClass(hidden);
  }

  var hidden = 'hidden';
  var disabled = 'disabled';

  $formUpdateUser.find('.js-edit-button').click(function () {
    $formUpdateUser.find('.js-firstname').removeAttr(disabled);
    $formUpdateUser.find('.js-lastname').removeAttr(disabled);
    $formUpdateUser.find('.js-date-picker').removeAttr(disabled);
    $formUpdateUser.find('.js-check-date-error').removeClass('form-disabled');
    $formUpdateUser.find('.js-group-button').removeClass(hidden);
  });

  $formUpdatePwd.find('.js-edit-button').click(function () {
    $formUpdatePwd.find('.js-current-pwd').addClass(hidden);
    $formUpdatePwd.find('.js-change-password').removeClass(hidden);
  });

  $('.js-edit-button').click(function () {
    var $form = $(this).closest('.js-account-form');

    $form.find('.js-box-background').removeClass('account-box');

    $(this).addClass(hidden);

    $('.js-notification').addClass(hidden);
    $('.js-delete-account').removeClass(hidden);
  });

  $formUpdateUser.find('.js-account-cancel').click(function (e) {
    $formUpdateUser.find('.js-firstname').attr(disabled, disabled);
    $formUpdateUser.find('.js-lastname').attr(disabled, disabled);
    $formUpdateUser.find('.js-date-picker').attr(disabled, disabled);
    $formUpdateUser.find('.js-check-date-error').addClass('form-disabled');
    $formUpdateUser.find('.js-group-button').addClass(hidden);
  });

  $formUpdatePwd.find('.js-account-cancel').click(function () {
    $formUpdatePwd.find('.js-password').val('');
    $formUpdatePwd.find('.js-confirmpass').val('');
    $formUpdatePwd.find('.js-current-pwd').removeClass(hidden);
    $formUpdatePwd.find('.js-change-password').addClass(hidden);
  });

  $('.js-account-cancel').click(function () {
    var $form = $(this).closest('.js-account-form');
    $form[0].reset();

    $form.find('.js-box-background').addClass('account-box');
    $form.find('.js-edit-button').removeClass(hidden);

    window.resetInput();

    $form.find('.js-firstname').trigger('change');

    $('.js-delete-account').addClass(hidden);
  });

  $formUpdateUser.find('.js-account-save').click(function (e) {
    e.preventDefault();

    if (!($formUpdateUser.checkValidity()) || !window.checkDateInput()) {
      return;
    }

    var birthDate = [$('.js-year').val(), $('.js-month').val(), $('.js-day').val()].join('-');
    $('.js-birth-date').val(birthDate);

    $formUpdateUser.submit();
  });

  $formUpdatePwd.find('.js-account-save').click(function (e) {
    e.preventDefault();

    if (!($formUpdatePwd.checkValidity()) || !window.checkDateInput()) {
      return;
    }

    $formUpdatePwd.submit();
  });

  var $subscribe = $('.js-subscribe');
  var $unsubscribe = $('.js-unsubscribe');

  var $subLink = $('.js-subscribe > a');
  var $unSubLink = $('.js-unsubscribe > a');

  function checkSubscription() {
    var subscription = window.getParameterByName('subscription');

    if (subscription === 'false') {
      $unsubscribe.removeClass(hidden);
    } else {
      $subscribe.removeClass(hidden);
    }
  }

  function updateSubscribeOnUrl (state) {
    var url = window.location.pathname + '?subscription=' + state;
    window.history.replaceState('', '', url);
  }

  $subLink.on('click', function () {
    updateSubscribeOnUrl(false);

    $(this).parent().addClass(hidden);
    $unsubscribe.removeClass(hidden);
  });

  $unSubLink.on('click', function () {
    updateSubscribeOnUrl(true);

    $(this).parent().addClass(hidden);
    $subscribe.removeClass(hidden);
  });

  checkSubscription();
});
